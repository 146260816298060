import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './Pages/scroll';
import HomePage from './components/HomePage';



function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Routes>   <Route path="/" element={<HomePage />} />

    </Routes>
    </BrowserRouter>
  );
}


export default App;
