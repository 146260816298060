import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaWhatsapp } from "react-icons/fa";
import logo from "./logo.png";
import sg from "./sg.png";
import freshktm from "./freshktm.png";
import ekagaj from "./ekagaj.jpeg";
import ssa from "./ssa.png";
import newera from "./newera.png";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    location: '',
    problem: '',
    deviceType: '',
    brandModel: '',
    email: '',
    devicePhoto: null
  });
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });
    try {
      const response = await axios.post('https://repair.consciousgrp.com:8003/api/v2/repair/create-repair', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.success) {
        toast.success(response.data.message); // Display success message from backend
        setFormData({
          name: '',
          mobileNumber: '',
          location: '',
          problem: '',
          deviceType: '',
          brandModel: '',
          email: '',
          devicePhoto: null
        });
        setSubmitted(true);
      } else {
        toast.error(response.data.message); // Display error message from backend
      }
    } catch (error) {
      console.error("Axios error:", error.response || error.message); // Log axios errors
      toast.error('Failed to submit order');
    }finally {
      setProcessing(false);
    }
  };
  
  return (
    <div className="bg-gray-100 font-[sans-serif] flex flex-col items-center">
      <div className="sm:flex">
        <div className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full my-6 sm:mr-4">
        <Link to="/" target="_blank"><div className='flex justify-center'><img
          className='h-[100px] items-center mb-4'
          src={logo} alt="logimg" /></div></Link>
          <h2 className="text-2xl font-bold mb-4 text-center">Who We Are</h2>
          <p className="text-gray-700 text-justify mb-6">
            We are <strong>Conscious Technology Pvt. Ltd.</strong> Located in the heart of Nepal at kathmandu, We are
            dedicated to providing top-notch, on-location repair services with utmost convenience and efficiency. After you place
            an order for our service, we will promptly call you to confirm your request and understand your problem in
            details. Our skilled technician will then arrive at your location and perform the repair right in front of you,
            all within just 1 hour. We pride ourselves on our quick response and high-quality service, ensuring your tech
            issues are resolved swiftly and effectively.
          </p>
          <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
          <p className="text-gray-700 mx-2 mb-2">
            <strong>Location: </strong>Tinkune, Kathmandu, Nepal
          </p>
          <p className="text-gray-700 mx-2">
            <strong>Contact Number: </strong>+9779818058002
          </p>
          <a cl
            href="https://wa.me/9779843238695" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex mt-4 text-green-600 hover:text-green-800"
          >
            <FaWhatsapp size={30} />
            <span className="ml-2 text-[16px] sm:text-[18px]">Chat with us on WhatsApp</span>
          </a>
        </div>
        {submitted ? (
        <div className="bg-white flex items-center justify-center shadow-md rounded-lg p-6 max-w-lg w-full my-6">
          <p className="text-green-700 text-center font-[600] text-lg mx-5">Thank You!!! We will contact you at your Mobile Number within 15 minutes.</p>
        </div>
      ) : (
        <div className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full my-6">
          <h2 className="text-2xl font-bold mb-4 text-center">Order Repair Service</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block font-[600] flex text-[600] text-gray-700">Name:<p className='text-red-600'>&nbsp;*</p></label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block font-[600] flex text-[600] text-gray-700">Mobile Number:<p className='text-red-600'>&nbsp;*</p></label>
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block font-[600] flex text-[600] text-gray-700">Email:<p className='text-red-600'>&nbsp;*</p></label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              />
            </div>
            <div className="mb-4">
            <label className="block font-[600] flex text-[600] text-gray-700">Location:<p className='text-red-600'>&nbsp;*</p></label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              />
            </div>
            <div className="mb-4">
            <label className="block font-[600] flex text-[600] text-gray-700">Device Type:<p className='text-red-600'>&nbsp;*</p></label>
              <select
                name="deviceType"
                value={formData.deviceType}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              >
                <option value="" disabled>Select device type</option>
                <option value="Laptop">Laptop</option>
                <option value="Computer">Computer</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block font-[600] flex text-[600] text-gray-700">Brand and Model Number:<p className='text-red-600'>&nbsp;*</p></label>
              <input
                type="text"
                name="brandModel"
                value={formData.brandModel}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block font-[600] flex text-[600] text-gray-700">Problem Description:<p className='text-red-600'>&nbsp;*</p></label>
              <textarea
                name="problem"
                value={formData.problem}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block font-[600] flex text-[600] text-gray-700">Device Photo:<p className='text-red-600'>&nbsp;*</p></label>
              <input
                type="file"
                required
                name="devicePhoto"
                accept="image/*"
                onChange={handleChange}
                className="mt-1 p-2 w-full border border-gray-400 rounded-sm"
              />
            </div>
            <input
              type="submit"
              value={processing ? "Processing..." : "Submit"}
              className="bg-green-600 text-white p-2 font-[600] rounded-md w-full hover:bg-green-700"
              disabled={processing}
            />
          </form>
        </div>
      )}
        <div className="bg-white shadow-md rounded-lg p-6 max-w-sm w-full my-6 sm:ml-4">
        <h2 className="text-2xl font-bold mb-10 text-center">Our Partners</h2>
          
        <Link to="https://samanantargroup.com/" target="_blank"><div className='flex justify-center'><img
          className='h-[70px] items-center mb-14'
          src={sg} alt="sg" /></div></Link>

        <Link to="https://ekagaj.com/" target="_blank"><div className='flex justify-center'><img
          className='h-[60px] items-center mb-14'
          src={ekagaj} alt="ekagaj" /></div></Link>

          <Link to="http://freshktm.com/" target="_blank"><div className='flex justify-center'><img
          className='h-[150px] items-center mb-14'
          src={freshktm} alt="freshktm" /></div></Link>

          <Link to="https://ssanepal.com/" target="_blank"><div className='flex justify-center'><img
          className='h-[80px] items-center mb-14'
          src={ssa} alt="ssa" /></div></Link>

          <Link to="https://neweratravels.com/" target="_blank"><div className='flex justify-center'><img
          className='h-[150px] items-center mb-3'
          src={newera} alt="newera" /></div></Link>

        </div>
      </div>
      <ToastContainer
       position="bottom-center"
      />
    </div>    
  );
};

export default HomePage;
